export default defineI18nConfig(nuxt => ({
    legacy: false,
    locale: 'de',
    messages: {
        en: {
        '500.headline': 'Well, this is embarrassing...',
        '500.subline': 'We are sorry, there is an internal problem.',
        '500.paragraph': 'We are now aware of this error and are working to fix it. Refresh the page or try again in 30 minutes.',
        '500.button': 'Back Home',
        '404.headline': 'Page not found',
        '404.subline': 'Oops, something went wrong!',
        '404.paragraph': 'It looks like this page has been moved or deleted.',
        '404.button': 'Continue here',
        },
        de: {
        '500.headline': 'Nun, das ist peinlich...',
        '500.subline': 'Es tut uns Leid, es liegt ein internes Problem vor.',
        '500.paragraph': 'Wir wissen jetzt von diesem Fehler und arbeiten daran, ihn zu beheben. Aktualisiere die Seite oder versuche es in 30 Minuten noch einmal.',
        '500.button': 'Zurück zur Startseite',
        '404.headline': 'Seite nicht gefunden',
        '404.subline': 'Hoppla, da ist etwas schief gelaufen!',
        '404.paragraph': 'Es sieht so aus als wurde diese Seite verschoben oder gelöscht.',
        '404.button': 'Hier geht\'s weiter',
        },
    },
    datetimeFormats: {
        de: {
            short: {
                day: "numeric",
                month: "short",
            },
            long: {
                day: "numeric",
                month: "short",
                year: "numeric",
            }
        },
        en: {
            short: {
                day: "numeric",
                month: "short",
            },
            long: {
                day: "numeric",
                month: "short",
                year: "numeric",
            }
        }
    }
}));
  